import { computed } from "vue";
import { useStore } from 'vuex';

export default function useModeApp(){
    const store = useStore()
    const mode = computed(() => store.state.users.mode);
    /**
     * switch mode set to vuex and set to localStorage(mode)
     **/
    const switch_mode = () => {
        store.commit('users/setMode', mode.value === "light" ? "dark" : "light") 
    }
    /**
     * add class to <body> (light-mode or dark-mode)  
     **/    
    const addClassToBody = () => {
        document.body.classList.remove('light-mode', 'dark-mode')
        document.body.classList.add(mode.value === "dark" ? "dark-mode" : "light-mode") 
    } 
    return {mode, switch_mode, addClassToBody}
}