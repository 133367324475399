<template> 
    <div style="text-align: center;margin-top: 10px;" class="cursor-pointer" @click="switch_mode()">
        <img v-if="mode == 'dark'" src="@/assets/images/icon/light-mode.svg" alt="" >
        <img v-else src="@/assets/images/icon/dark-mode.svg" alt="" >
    </div>
    <div class="body-main" v-if="!isLogin()">
        
        <div class="form-signin main-overlay">
            <div class="mb-4">
                <img v-if="mode == 'dark'" src="@/assets/images/logo_dark.svg" style="height: 60px; margin-bottom: 1px;"  alt="" >
                <img v-else src="@/assets/images/logo.svg" style="height: 60px; margin-bottom: 1px;"  alt="" >    
            </div>

            <div class="form-group form-group-cs mb-3" v-if="step == 1">
                <input type="text" class="form-control form-cs mb-3" v-model="username" placeholder="E-Mail" required />
                <button class="btn btn-lg w-100 btn-sea-blue m-0" @click="validate()">Get Started</button>
              </div>  
            <form @submit.prevent="handleLogin" method="post" v-else>               
                <div v-if="message" class="alert alert-danger text-left" role="alert">
                    {{ message }}
                </div>               
                <div class="mb-3 form-ct">
                    <input type="text" class="form-control" v-model="username" placeholder="Username" required>
                </div>
                <div class="mb-1 form-ct">
                    <input type="password" class="form-control" v-model="password" placeholder="Password" required>
                </div>
                
            
                <div class="mt-2">
                <div class="text-link pull-left">
                    <input class="form-check-input" type="checkbox" id="rememberMe" v-model="rememberme">  
                    <label for="rememberMe">  Remember me</label>
                    | <router-link to="/forgot-password">Forgot your password?</router-link>                   
                </div>
                <div class="pull-right">
                    <router-link to="/register" class="btn btn-border-blue btn-sm" >REGISTER</router-link>
                    <button class="btn btn-sea-blue btn-sm" type="submit">SIGN IN <font-awesome-icon icon="chevron-right" :style="{ marginLeft: '5px' }" /></button>
                </div>   
                </div>
                <div v-if="loading" class="overlay">
                    <div class="loadding_circle"></div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { isLogin } from "@/utils";
import useModeApp  from "@/hooks/useModeApp.js"
export default {
    setup(){
        const store = useStore()
        const router = useRouter() 
        

        if (isLogin()) {
            router.push("/");
        }

        const loading = ref(false);
        const rememberme = ref(false);
        const username = ref("");
        const password = ref("");
        const message = ref("");
        const step = ref(1);
        const chcekInternalUser = computed(() => store.state.auth.is_internal_user); 

        /**
         * On click Get Started
         * @return validate and check condition the rule of login 
         */
        const validate = () => {
            store.dispatch("auth/check_user_oidc", {username : username.value} ).then(
            () => {
                if(chcekInternalUser.value){
                    store.dispatch("auth/get_authorize_endpoint").then(
                    () => {
                        window.location.href = localStorage.getItem('oidc_endpoint');
                    });
                } else {
                    step.value = 2
                }
            });
        }


        /**
         * On click SIGN IN 
         * @return if success return router.push("/") else return message {string} 
         */
        const handleLogin = () => {            
            message.value = ""            
            if (username.value.length && password.value.length) {
                loading.value = true;
                store.dispatch("auth/login", { username : username.value, password : password.value, remember : rememberme.value }).then(
                    () => {
                        if(localStorage.redirectTo){                       
                            router.push(localStorage.redirectTo)
                            localStorage.removeItem('redirectTo')                        
                        } else
                            router.push("/");
                    },
                    (error) => {
                        loading.value = false;
                        let tempmsg = (error && error.response);
                        if (tempmsg && tempmsg.status === 401) {
                            message.value = "Username or password is incorrect.";
                        }else if(tempmsg && tempmsg.data && tempmsg.data.message){
                            message.value = tempmsg.data.message;
                        }else {
                            message.value = "Username or password is incorrect.";
                        }                
                    }
                );
            }
        }



        //-------------------------- switch mode  ------------------------[start]  
        const { mode, switch_mode, addClassToBody } = useModeApp();      
        watch(mode, ()  => { 
            addClassToBody() 
        })
        onMounted(() => {
            addClassToBody()
        });
        onUnmounted(() => {
            document.body.classList.remove('light-mode', 'dark-mode')
        })
        //-------------------------- switch mode  ------------------------[end]

        
        return {username, password, message, loading, validate, handleLogin, isLogin, step, switch_mode, mode, rememberme}

    },
    
     
     
}
</script>